export default {
  "resource": {
    "one": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vehicle"])}
    },
    "more": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vehicles"])}
    },
    "compound": {
      "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🚗"])}
  },
  "headlines": {
    "resourceBooking": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.one.upper"), " Booking"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Calendar"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "resources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.more.upper")])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Calendar"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "labels": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "geotabID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geotab ID"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger Seats"])},
    "permissionLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Level"])},
    "licensePlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Plate *"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Odometer (km)"])},
    "charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Charge (%)"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up date"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop-off date"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "bookedUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked until"])},
    "bookedFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked from"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "partlyAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partly available"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "addResource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Add ", _linked("resource.one.upper"), " Manually"])},
    "updateGeotabResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Odometer/Charges from Geotab"])},
    "importResource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Import ", _linked("resource.more.upper"), " from Geotab"])},
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])},
    "resource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("resource.one.upper")])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "uid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "isDividable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Dividable"])},
    "subresources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Sub", _linked("resource.more.lower")])},
    "addSubresource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Add Sub", _linked("resource.one.lower")])},
    "selectSubresources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Select Sub", _linked("resource.more.lower")])},
    "onlyShowAvailableResources": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Only show available ", _linked("resource.more.upper")])},
    "onlyOneResource": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["Only display one ", _linked("resource.one.upper")])},
    "onlyUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only display your bookings"])},
    "separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separator"])},
    "arraySeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Array Separator"])},
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "loginGeotab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to Geotab:"])},
    "geoUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geotab Username"])},
    "geoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geotab Password"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "vehicleFilter": {
      "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
      "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "Odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odometer"])},
      "Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats"])}
    },
    "timeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time"])}
  },
  "alerts": {
    "geotabLoginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Login to Geotab!"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Login!"])},
    "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name can not contain: ^<>/\\\":?  or be: 'service'."])},
    "bookingconflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chosen details cause a conflict with an already exsiting Booking."])},
    "noResource": {
      "heading": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["No ", _linked("resource.more.upper")])},
      "text": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["There is no ", _linked("resource.one.lower"), " to view. Please setup a ", _linked("resource.one.lower"), " in the ", _linked("headlines.settings"), "."])}
    },
    "request": {
      "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorized!"])}
    },
    "db": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully connected to the Database."])}
    }
  },
  "comp": {
    "login": {
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign in"])}
    },
    "calendar": {
      
    },
    "overview": {
      
    },
    "booking": {
      "exampleSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. John Doe for trip to Auckland CBD."])},
      "resourcesBooked": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["One ", _linked("resource.one.lower"), " is already booked, or filtered out."]), _normalize([_interpolate(_named("count")), " ", _linked("resource.more.lower"), " are already booked, or filtered out."])])},
      "allResourcesBooked": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["All ", _linked("resource.more.lower"), " are booked in this time frame, or filtered out."])},
      "bookingSuccess": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Booked"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You've booked ", _interpolate(_named("resources")), " from ", _interpolate(_named("startDate")), " to ", _interpolate(_named("endDate")), "."])}
      },
      "bookingFailure": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking was not successful."])}
      }
    },
    "settings": {
      
    },
    "import": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import multiple bookings using the CSV format."])}
    },
    "export": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all bookings as a CSV."])}
    },
    "resource": {
      "exampleName": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["e.g. ", _linked("resource.one.upper"), " 2"])}
    },
    "vehicle": {
      "exampleMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Tesla"])},
      "exampleModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Model S"])},
      "exampleYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 2022"])},
      "exampleGeotabID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. b1"])},
      "exampleCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 78"])},
      "exampleLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. ABC123"])},
      "exampleOdometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 12345"])},
      "exampleSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 5"])},
      "examplePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 2"])}
    }
  },
  "mail": {
    "salutation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dear ", _interpolate(_named("recipient"))])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your Booking at"])},
    "confirmation": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✅ Booking Conformation"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Booking was successful."])}
    },
    "deletion": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❌ Booking Deletion"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Booking was deleted."])}
    },
    "change": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🔄 Booking Change"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of your bookings was changed:"])}
    },
    "reminder": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❕ Reminder"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following resources are coming up soon:"])},
      "bookedFrom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Booked from ", _interpolate(_named("organizer"))])}
    },
    "notifyOnEnd": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ℹ End of stay"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following bookings end today:"])}
    }
  }
}