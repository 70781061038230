<template>
  <div class="text-center" id="loginPage">
    <form class="form-signin" @submit.prevent="login()">
      <div class="form-floating">
        <input type="username" class="form-control" id="floatingInput" placeholder="{{ $t('labels.geoUsername') }}" v-model="email" required />
        <label for="floatingInput">{{ $t('labels.geoUsername') }}</label>
      </div>
      <div class="form-floating">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          style="margin-bottom: 0px"
          placeholder="{{ $t('labels.geoPassword') }}"
          v-model="password"
          required
        />
        <label for="floatingPassword">{{ $t('labels.geoPassword') }}</label>
      </div>
      <div class="form-floating">
        <input class="form-control" id="floatingInput2" placeholder="'Database'" v-model="database" style="margin-bottom: 10px" required/>
        <label for="floatingInput2">Database</label>
      </div>
      <button class="w-100 btn btn-lg btn-primary" type="submit" :disabled="inactive">Sign in</button>
    </form>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Login',
  props: {
    role: String,
    geotabDevices: {
      type: Object,
      default: function () {
        return {
          data: []
        }
      },
    },
  },
  data() {
    return {
      password: '',
      email: '',
      database: '',
      geotabDevice: this.geotabDevices,
      inactive: false,
    }
  },
  methods: {
    async login() {
      this.inactive = true
      let successfulLogin = false
      try {
        const res = await axios.post(
          process.env.VUE_APP_BACKEND_URL + '/api/admin/geotab/devices',
          {
            username: this.email,
            password: this.password,
            database: this.database ? this.database : false
          },
          { withCredentials: true },
        )
        if (res.status === 200) {
          this.geotabDevice = res.data
          this.inactive = false
          successfulLogin = true
        }
        console.log(res)
      } catch (error) {
        alert("Invalid username, password or database. Please check your credentials and try again")
        console.log("Failed getting geotab data")
        this.inactive = false
      }
      if (successfulLogin) {
        await this.extractRelevantData()
        //this.password = ''
      }
    },
    async extractRelevantData() {
      this.devices = []
      for (let i = 0; i < this.geotabDevice.data.length; i++) {
        //make model year if applicable
        let currentDeviceData = this.geotabDevice.data[i];
        let geoMake = ""
        let geoModel = ""
        let geoYear = ""
        if (currentDeviceData.name.length) {
          let j = currentDeviceData.name.indexOf(' ')
          geoMake = currentDeviceData.name.slice(0,j)
          geoModel = currentDeviceData.name.slice(j+1)
        }
        if (currentDeviceData.comment.length) {
          let j = currentDeviceData.comment.indexOf(' ')
          geoYear = currentDeviceData.comment.slice(0,j)
        }
        
        //date for imported
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy
        
        //create the vehicle to be added
        let vehicle = {
          make: geoMake,
          model: geoModel,
          year: geoYear,
          name: currentDeviceData.licensePlate,
          odometer: '',
          charge: '',
          seats: '',
          group: [this.role],
          fuel: '',
          geotabID: currentDeviceData.id,
          permissionLevel: '',
          description: '',
          history: today,
          chargeHistory: (new Date()).toISOString(),
          img: undefined,
          color: this.correctColorLuminance(this.generateRandomColorHex()),
        }
        this.devices.push(vehicle)
      }
      this.$emit('login', [this.email, this.password, this.devices, this.database])
    },

    correctColorLuminance(hex) {
      if (hex) {
        // HEX to RGB
        var rgb = this.hexToRgb(hex)
        var luminance = this.calcRelativeLumiance(rgb)
        // setting 0.4 as border
        while (luminance > 0.4) {
          rgb = [rgb[0] >= 2 ? rgb[0] - 2 : 0, rgb[1] >= 7 ? rgb[1] - 7 : 0, rgb[2] >= 1 ? rgb[2] - 1 : 0]
          luminance = this.calcRelativeLumiance(rgb)
        }
        return this.rgbToHex(rgb[0], rgb[1], rgb[2])
      }
    },

    generateRandomColorHex() {
      const rgb = [255, 255, 255].map(function (v) {
        return Math.round(Math.random() * v)
      })
      return this.rgbToHex(rgb[0], rgb[1], rgb[2])
    },

    calcRelativeLumiance(rgb) {
      // CALC relative Lumiance https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
      const copy = rgb.map(function (v) {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
      })
      return copy[0] * 0.2126 + copy[1] * 0.7152 + copy[2] * 0.0722
    },

    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
    },

    hexToRgb(hex) {
      const result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex)
      return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    },
  },
  beforeMount() {
    this.$root.isLoading = false
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginPage {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

button:disabled {
  border: 1px solid black;
  background-color: dimgrey;
}
</style>