import { createRouter, createWebHistory } from 'vue-router'
import Settings from '../components/Settings.vue'
import Vehicles from '../components/Vehicles.vue'
import Home from '../components/Home.vue'
import Unauthorised from '../components/Unauthorised.vue'
//import axios from 'axios'

const routes = [
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { 
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: Vehicles,
    meta: { 
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      requiresAuth: true,
      admin: false
    }
  },
  {
    path:'/unauthorised',
    name: 'Unauthorised',
    component: Unauthorised,
    meta: { 
      requiresAuth: false,
      admin: false
    }
  },
  
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
