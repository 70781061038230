<template>
  <div class="container">
    <p>Enter a list (separated by a comma or new line) of suggested locations that may be used by your fleet.</p>
    <textarea 
      class="form-control" 
      id="floatingInputDescription"
      rows="5"
      style="border-radius: 15px 15px 0 15px; max-width: 400px;"
      v-model="depoString"
      :disabled="this.mode ==='view'"
    ></textarea>
    <button v-if="mode === 'edit'" v-on:click="updateDepos()" class="btn-powertrip" style="margin-top: 1em; margin-left: 0;">
      Update Depos
    </button>
    <button v-if="mode === 'edit'" class="btn-cancel" v-on:click="this.mode = 'view'" style="margin-top: 1em;">
      Cancel
    </button>
    <button v-else class="btn-cancel" v-on:click="this.mode = 'edit'" style="margin-top: 1em; margin-left: 0;">
      Edit Depos
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: { },
  name: 'DepoList',
  props: ['role'],
  data() {
    return {
      depoString: "",
      newDepos: [],
      mode: 'view',
    }
  },
  methods: {
    depoToString(depo) {
      this.depoString = ''
      if (depo.length) {
        depo.forEach(item => {
          this.depoString = this.depoString + item + '\n'
        })
      }
    },

    stringToDepoArray() {
      this.newDepos = this.depoString.split(/[\n,]/);
      for (let i = 0; i < this.newDepos.length; i++) {
        this.newDepos[i] = this.newDepos[i].trim();
        if (!this.newDepos[i]) {
          this.newDepos.splice(i, 1);
          i--
        }
      }
    },

    async updateDepos() {
      this.stringToDepoArray()
      let depoObject = {
        role: this.role,
        depos: this.newDepos
      }
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/depos', depoObject, {
          withCredentials: true,
        })
        console.log(res.data)
        if (res.status === 200) {
          this.mode = "view"
        }
      } catch (error) {
        alert("Something went wrong...")
        console.log(error);
      }
    },
    async getDepos() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/depos', {
          params: {
            role: this.role,
          },
          withCredentials: true,
        })
        if (res.status === 200) {
          this.depoToString(res.data.depos)
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
  beforeMount() {
    this.getDepos();
  },
  mounted() {
    
  },
  watch: {
    role: async function() {
      this.mode = 'view'
      await this.getDepos()
    },
    mode: async function() {
      if (this.mode === 'view') await this.getDepos();
    },
  }
}
</script>

<style>

</style>