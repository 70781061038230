<template>
  <div>
    <!-- <button v-on:click="this.deleteAll()">Reset vehicle database (temporary, for testing)</button> -->
    <div v-if="!this.runningGeotabAPI" class="container">
      <div v-if="resources.length > 0" class="list-group mb-3 border" style="max-height: 400px; overflow-y: scroll">
        <template v-for="resource of resources" v-bind:key="resource.name">
          <a
            v-if="resource.group.includes(this.role)"
            class="list-group-item list-group-item-action gap-3 py-3"
            aria-current="true"
          >
            <div class="row align-items-center">
              <img v-bind:src="resource.img" width="32" height="32" class="rounded-circle flex-shrink-0 col-auto" />
            
              <div class="ps-0 col-5 me-auto">
                <h6 class="mb-0">{{ resource.name }}</h6>
                <p class="mb-0 opacity-75 d-none d-md-block">{{ resource.description }}</p>
              </div>
              <div class="col-auto d-none d-md-block">
                <span class="opacity-75 small">{{ resource.year }}&ensp;</span>
                <span class="opacity-75 small">{{ resource.make }}&ensp;</span>
                <span class="opacity-75 small">{{ resource.model }}</span>
              </div>
              <div class="col-auto d-none d-md-block">
                <span class="opacity-75 small">{{ resource.odometer }} km</span>
              </div>
              
              <div class="col-auto">
                <button type="button" class="btn-cancel" v-on:click="clickView(resource)">
                  <div class=" d-none d-md-block">
                    <i class="bi bi-eye"></i>
                    <span class="ps-1">{{ $t('labels.view') }}</span>
                  </div>
                  <i class="bi bi-eye d-block d-md-none"></i>
                </button>
                <button type="button" class="btn-cancel" v-on:click="clickEdit(resource)">
                  <div class=" d-none d-md-block">
                    <i class="bi bi-pencil"></i>
                    <span class="ps-1">{{ $t('labels.edit') }}</span>
                  </div>
                  <i class="bi bi-pencil d-block d-md-none"></i>
                </button>
              </div>
            </div>
          </a>
        </template>
      </div>
      <div class="modal fade" id="importModal" tabindex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="importModalLabel">
                {{ $t('labels.loginGeotab') }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <GeoForm
                :devices="geotabResources"
                :role="role"
                v-on:login="handleGeotabLogin"
                v-on:cancel="importModal.hide()"
              ></GeoForm>
            </div>
          </div>
        </div>
      </div>
      <ResourceForm
        v-if="resourceFormMode !== '' && resourceFormMode !== 'view'"
        :resource=resourceToEdit
        :mode="resourceFormMode"
        v-on:add="addResource"
        v-on:edit="editResource"
        v-on:cancel="resourceFormMode = ''"
        v-on:delete="deleteResource"
        ref="resourceform"
        id="resourceform"
        style="max-width: 650px"
      ></ResourceForm>
      <ResourceViewForm
        v-if="resourceFormMode === 'view'"
        :resource="resourceToView"
        :mode="resourceFormMode"
        v-on:cancel="resourceFormMode = ''"
        ref="resourceform"
        id="resourceviewform"
        style="max-width: 650px"
      ></ResourceViewForm>
      <button v-if="resourceFormMode === ''" type="button" class="btn-powertrip" v-on:click="importResources()">
        {{ $t('labels.importResource') }}
      </button>
      <button v-if="resourceFormMode === ''" type="button" class="btn-cancel" v-on:click="resourceFormMode = 'add'; resourceToEdit = undefined">
        {{ $t('labels.addResource') }}
      </button>
      <button v-if="resourceFormMode === '' && resources.length > 0" type="button" class="btn-powertrip" v-on:click="updateResources()" style="float:right; margin-bottom: 1em;">
        Update Geotab Vehicles
      </button>
    </div>
    <div v-else>Importing or updating vehicles from geotab. Please remain on this page until they appear.</div>
  </div>
</template>

<script>
import axios from 'axios'
import ResourceForm from '../Forms/ResourceForm3.vue'
import ResourceViewForm from '../Forms/ResourceViewForm.vue'
import { Modal } from 'bootstrap'
import GeoForm from '../Forms/GeoForm.vue'

export default {
  components: { 
    ResourceForm,
    ResourceViewForm,
    GeoForm
  },
  name: 'ResourceList',
  props: ['resources', 'role'],
  data() {
    return {
      email: '',
      password: '',
      database: '',
      resourceFormMode: "",
      importMode: "",
      resourceToEdit: undefined,
      resourceToView: undefined,
      importModal: undefined,
      geotabResources: undefined,
      geoData: undefined,
      vehiclesToUpdate: undefined,
      newVehicles: undefined,
      existingVehicles: undefined,
      advancedGeoDataSuccess: false,
      runningGeotabAPI: false,
      oldDate: {},
    }
  },
  methods: {
    async deleteAll() {
      if (confirm("Are you sure you want to delete all vehicles?")) {
        try {
          const res = await axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/admin/resources', {
            withCredentials: true,
          })
          console.log(res.data)
          if (res.status === 200) {
            this.$root.getResources()
            this.resourceFormMode = ""
          }
        } catch (error) {
          if (error.response.status === 401) {
            this.$router.push('login')
          } else {
            console.log(error.response.data)
          }
        }
      }
    },
    clickView(resource){
      this.resourceFormMode = 'view'
      this.resourceToView = resource
    },
    clickEdit(resource){
      this.resourceFormMode = 'edit'
      this.resourceToEdit = resource
      this.oldData = { charge: this.resourceToEdit.charge, chargeHistory: this.resourceToEdit.chargeHistory }
    },
    async editResource(resource){
      resource.history = this.getToday();
      resource.chargeHistory = (this.oldData.charge && resource.charge !== this.oldData.charge) ? (new Date()).toISOString() : this.oldData.chargeHistory
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/resource/change', resource, {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.$root.getResources()
          this.resourceFormMode = ''
        }
      } catch (error) {
        if (error.response.status === 401) {
          console.log("error editing resource") //this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
    },
    getToday() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return dd + '/' + mm + '/' + yyyy
    },
    async deleteResource(name) {
      if (confirm("Are you sure you want to delete this vehicle, license: " + name)) {
        try {
          const res = await axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/admin/resource', {
            params: { name: name },
            withCredentials: true,
          })
          if (res.status === 200) {
            this.$root.getResources()
            this.resourceFormMode = ""
          }
        } catch (error) {
          if (error.response.status === 401) {
            this.$router.push('login')
          } else {
            console.log(error.response.data)
          }
        }
      }
    },

    async addResource(resource) {
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/resource', resource, {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.$root.getResources()
          this.$refs.resourceform.clear()
          this.resourceFormMode = ""
        }
      } catch (error) {
        console.log(error)
        if (error.response.status === 401) {
          console.log("error adding resource") //this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
    },
    async importResources() {
      this.importMode = "import"
      this.importModal.show()
    },
    async updateResources() {
      this.importMode = "update"
      this.importModal.show()
    },
    async handleGeotabLogin(data) {
      this.runningGeotabAPI = true
      
      //format the data
      this.formatGeotabData(data)

      this.importModal.hide()
      //work out what mode and call the appropriate function
      if (this.importMode === "import") {
        await this.importGeotabData()
      } else if (this.importMode ==="update") {
        await this.updateGeotabData()
      }

      if (this.advancedGeoDataSuccess) {
        this.$root.getResources()
      }

      //reset the data, ready for the next call
      this.existingVehicles = undefined
      this.geoData = undefined
      this.newVehicles = undefined
      this.email = ''
      this.password = ''
      this.database = ''
      this.advancedGeoDataSuccess = false
      this.runningGeotabAPI = false
      this.importMode = ""
      
    },

    async formatGeotabData(dataFromGeoForm) {
      //extract relevant info from the passed data
      this.email = dataFromGeoForm[0]
      this.password = dataFromGeoForm[1]
      this.database = dataFromGeoForm[3]
      this.geoData = dataFromGeoForm[2]
      
      let tempNewVehicles = []
      let tempExistingVehicles = []
      for (let i = 0; i < this.geoData.length; i++) {
        let currentName = this.geoData[i].name

        //check that there is a license, if not, next iteration
        if (currentName === '') continue

        //check if the license already exists
        let licenseExists = this.resources.filter(function(o) {
          return o.name === currentName;
        }).length > 0;

        //is the vehicle existing or new?
        licenseExists ? tempExistingVehicles.push(this.geoData[i]) : tempNewVehicles.push(this.geoData[i])
      }

      this.newVehicles = [...tempNewVehicles]
      this.existingVehicles = [...tempExistingVehicles]
    },

    async importGeotabData() {
      //Work out if there is any new data to be added?
      // let addData = false
      // this.newVehicles.length ? addData = confirm('There are ' + this.newVehicles.length + ' new vehicle(s) to be added. Would you like to add them now?\n\n This will take approximately ' + (Math.ceil(this.newVehicles.length / 3)) + ' minutes. While this runs, please remain logged in.') : alert('There were no unique vehicles found in Geotab. ' + this.geoData.length + ' vehicle(s) were found in total.')
      if (this.runningGeotabAPI) {
        this.vehiclesToUpdate = [...this.newVehicles]
        let newVehicles = await this.addGeotabResources()

        for (let i = 0; i < this.newVehicles.length; i++) {
          try {
            const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/resource', newVehicles[i], {
                withCredentials: true,
            })
            if (res.status === 200) {
                this.advancedGeoDataSuccess = true
            }
          } catch (error) {
              this.advancedGeoDataSuccess = false
          }
        }
      }
    },

    async updateGeotabData() {
      //work out what vehicles are already existing
      this.vehiclesToUpdate = [...this.existingVehicles]
      let updatedVehicles = await this.addGeotabResources()

      for (let i = 0; i < updatedVehicles.length; i++) {
        try {
          const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/resource/change', updatedVehicles[i], {
            withCredentials: true,
          })
          if (res.status === 200) {
            this.advancedGeoDataSuccess = true
          }
        } catch (error) {
            this.advancedGeoDataSuccess = false
        }
      }
    },

    async addGeotabResources() {
      //Axios call to pull the rest of the data from appropriate data from geotab
      try {
        const res = await axios.post(
          process.env.VUE_APP_BACKEND_URL + '/api/admin/geotab/status',
          {
            username: this.email,
            password: this.password,
            database: this.database ? this.database : false,
            vehicles: this.vehiclesToUpdate,
            mode: this.importMode,
          },
          { withCredentials: true }
        )
        if (res.status === 200) {
          return res.data.data
        }
      } catch (error) {
        console.log("Failed adding/updating geotab resources")
      }
    },
  },
  beforeMount() {
  },
  mounted() {
    this.importModal = new Modal(document.getElementById('importModal'), {})
  },
}


</script>

<style>
  .btn {
    margin-right: 2px;
  }
</style>