<template>
  <div v-if="this.needDLicNo">
    <form class="container" @submit.prevent="updateDLicNo()" style="margin-top:2em">
      Welcome to your vehicle booking system!<br/>
      <br/>
      To gain access to the booking system, please enter your Drivers license number:
      <br/>
      <br/>
      <input type="text" v-model="dlicno"/>
      <button type="submit" class="btn-powertrip" style="margin-left: 20px">
        Add DLicNo
      </button>
    </form>
    
  </div>
  <div v-else>
    <header class="mb-3 border-bottom bg-white bg-opacity-25">
      <div class="container">
        <div class="d-flex flex-row align-items-center">
          <div class="me-auto">
            <a href="/" class="nav-link link-dark d-flex align-items-center">
              <img src="./assets/Power-Trip-Logo-booking-app.png" style="width:auto; height:auto; max-height: 60px;"/>
              <!-- <i :class="'fs-1 ' + iconClass"></i>
              <span class="fs-4 ms-2 d-none d-md-block">{{ $t('headlines.resourceBooking') }}</span> -->
            </a>
          </div>
          <div>
            <router-link to="/" class="nav-link link-dark d-flex align-items-center">
              <i class="fs-4 bi bi-calendar-event"></i>
              <span class="ms-1 d-none d-md-block">Booking Calendar</span>
            </router-link>
          </div>
          <div>
            <router-link v-if="isAdmin" to="/vehicles" class="nav-link link-dark d-flex align-items-center">
              <i class="fs-4 bi bi-car-front"></i>
              <span class="ms-1 d-none d-md-block">Vehicles</span>
            </router-link>
          </div>
          <div>
            <router-link v-if="isAdmin" to="/settings" class="nav-link link-dark d-flex align-items-center">
              <i class="fs-4 bi bi-gear"></i>
              <span class="ms-1 d-none d-md-block">{{ $t('headlines.settings') }}</span>
            </router-link>
          </div>
          <div>
            <div v-if="auth" class="nav-link link-dark d-flex align-items-center">
              <i class="fs-4 bi bi-person-circle"></i>
              <span class="ms-1 d-none d-md-block">{{ name }}</span>
              <div style="position: relative; top: 15px; right: 30px;">
                <a 
                  :href="keycloakUrl + 'realms/' + keycloakRealm + '/protocol/openid-connect/logout?redirect_uri=' + frontendUrl" 
                  class="mb-0 opacity-75 d-none d-md-block" 
                  style="display: block; text-decoration:none; color:black; font-size:xx-small"
                >
                  Logout
                </a>
              </div>
            </div>
            <router-link v-else to="/login" class="nav-link link-dark d-flex align-items-center">
              <i class="fs-4 bi bi-box-arrow-in-right"></i>
              <span class="ms-1 d-none d-md-block">{{ $t('headlines.login') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div v-if="isLoading" class="position-absolute top-50 start-50 translate-middle">
      <div class="spinner-grow me-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow me-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <router-view :class="isLoading ? 'd-none' : 'd-block'" :resources="this.resources" />

    <footer class="py-3 border-top">
      <div class="container">
        <div class="d-flex align-items-center">
          <a href="/" class="text-decoration-none link-dark lh-1">
            <img src="./assets/Power-Trip-Logo-12-BLUE.png" style="width:auto; height:auto; max-height: 50px;">
          </a>
          <span class="ps-2 text-muted">© Power Trip Limited {{ new Date().getFullYear() }}</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import jp from 'jsonpath'
export default {
  props: ['keycloak'],
  data() {
    return {
      dlicno: '',
      needDLicNo: '',
      userID: '',
      auth: false,
      name: '',
      email: '',
      firstName: '',
      lastName: '',
      username: '',
      isAdmin: false,
      adminRoles: [],
      isService: false,
      resources: [],
      resourceNames: [],
      reload: null,
      isLoading: true,
      useSubresources: false,
      useService: false,
      iconClass: process.env.VUE_APP_ICON_CLASS,
      frontendUrl: process.env.VUE_APP_FRONTEND_URL,
      keycloakRealm: process.env.VUE_APP_KEYCLOAK_REALM,
      keycloakUrl: process.env.VUE_APP_KEYCLOAK_URL,
      keycloakClient: process.env.VUE_APP_KEYCLOAK_CLIENT
    }
  },
  methods: {
    async getUser() {
      let tempUser = await this.getUserData()
      this.adminRoles = tempUser.adminRoles;
      if (tempUser.globalAdmin) this.adminRoles.push('global_admin')
      this.needDLicNo = (tempUser.DLicNo) ? false : true
      await this.keycloak.loadUserProfile().then(result => {
        this.firstName = result.firstName
        this.lastName = result.lastName
        this.username = result.username
        this.name =  result.firstName + ' ' + result.lastName
        this.email = result.email
        this.userID = result.id
      })
      this.isAdmin = (this.keycloak.hasResourceRole('admin') || this.keycloak.hasResourceRole('global_admin'))
      if (this.name) this.auth = true
    },
    async updateDLicNo() {
      let dlicnoData = {
        dlicno: this.dlicno
      }
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/dlicno', dlicnoData, {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.needDLicNo = false;
        }
      } catch (error) {
        console.log(error)
        if (error.response.status === 401) {
          console.log("error fetching resources") //this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
      
    },
    async getUserData() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/user', {
          withCredentials: true,
        })
        if (res.status === 200) {
          return res.data.user
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getResources() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/resource', {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.resources = res.data.resources
          this.resourceNames = jp.query(res.data.resources, '$..name')
        }
      } catch (error) {
        console.log(error)
        if (error.response.status === 401) {
          console.log("error fetching resources") //this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
    },
    async getResourcesAvailability(startDate, endDate, bookingDurationInDays = undefined) {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/resource/search', {
          params: {
            startDate: startDate,
            endDate: endDate,
            bookingDurationInDays: bookingDurationInDays,
          },
          withCredentials: true,
        })
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
        return { available: [], unavailable: [] }
      }
    },
    getResourceByName(name) {
      for (const resource of this.resources) {
        if (resource.name === name) {
          return resource
        }
      }
      return null
    },
    getRoles() {
      let client = this.keycloakClient
      let roles = [...this.keycloak.resourceAccess[client].roles]
      if(roles.indexOf('general_user') >= 0) roles.splice(roles.indexOf('general_user'), 1)
      if(roles.indexOf('admin') >= 0) roles.splice(roles.indexOf('admin'), 1)
      return roles
    },
    async getUserandResources() {
      await this.getUser()
      await this.getResources()
      this.reload = setInterval(() => {
        this.getResources()
      }, 60 * 1000)
      this.isLoading = false
    },
    async updateKeycloakUser() {
      await this.getUser()
      let theUser = {
        uid: this.username,
        mail: this.email
      }
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/updateUsers', theUser, {
          withCredentials: true
        })
        if (res.status === 200) {
          console.log(res.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  beforeMount() {
    //this.updateKeycloakUser()
    document.title = this.$t('headlines.resourceBooking') + ' ' + this.$t('resource.emoji')
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 75px !important; /* Margin bottom by footer height */
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  z-index: -999;
}
</style>
