<template>
  <div class="container">
    <p>{{ $t('comp.export.description') }}</p>
    <button type="button" class="btn-powertrip" v-on:click="this.export()">
      {{ $t('labels.export') }}
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {},
  props: ['role'],
  name: 'ExportBookings',
  data() {
    return {
      separatorList: [
        { name: 'Tab', value: '\t' },
        { name: 'Comma', value: ', ' },
        { name: 'Semicolon', value: '; ' },
        { name: 'Colon', value: ': ' },
      ],
      separator: '; ',
      arraySeparator: ' / ',
      csv: '',
    }
  },
  methods: {
    async export() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/admin/csv/booking', {
          params: { separator: this.separator, arraySeparator: this.arraySeparator, role: this.role },
          withCredentials: true,
        })
        if (res.status === 200) {
          this.csv = res.data.csv
          this.separator = res.data.separator
          this.arraySeparator = res.data.arraySeparator
          this.csvExport()
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
    },
    csvExport() {
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.csv);
      anchor.target = '_blank';
      anchor.download = 'bookingHistory.csv';
      anchor.click();
    }
  },
}
</script>

<style>
</style>