<template>
  <div>
    <div class="modal fade" id="bookingModal" tabindex="-1" aria-labelledby="bookingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style="width: 400px;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bookingModalLabel">
              {{ (selectedBooking.resource && selectedBooking.organizer) ? selectedBooking.resource.concat(' - ').concat(selectedBooking.organizer.split(' &lt;')[0]) : "Viewing Booking..." }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <BookingForm
              :booking="this.selectedBooking"
              :mode="this.mode"
              :role="this.role"
              v-on:cancel="this.infoModal.hide()"
              v-on:edit="this.mode='edit'"
              v-on:done="editBooking"
              v-on:delete="deleteBooking"
            ></BookingForm>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="this.roles.length" class="col-auto form-floating" style="width: 100%; max-width:300px; margin-left: 15px;">
        <select class="form-select" id="floatingUserRoles" v-model="role" style="border-radius: 15px;">
          <option v-for="role of this.roles" :key="role">{{ role }}</option>
        </select>
        <label for="floatingUserRoles">Fleet</label>
      </div>
      <div v-else class="flex-form" style="text-align: center;">
        <i style="width:100%">You are not enrolled in any fleets.</i>
      </div>
    </div>
    <div v-if="this.role && (this.$root.adminRoles.includes(this.role) || this.$root.adminRoles.includes('global_admin'))">
      <div style="display: block; align-items: flex-start; max-height: 450px;">
        <div class="booking-container" style="max-width: 2000px; margin: 1rem; max-height:450px;">
          <FullCalendar2 id="fullCalendar2" :options="calendarOptions" style="max-height: 450px; padding: 1rem;"/>
        </div>
      </div>
    </div>
    <div v-else style="margin: 15px">
      Please select a fleet that you have admin access.
    </div>
  </div>
</template>

<script>
//import '@fullcalendar/core/vdom'
import BookingForm from './Forms/BookingForm.vue'
import FullCalendar2 from '@fullcalendar/vue3'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import iCalendarPlugin from "../libs/icalendar/main"
import allLocales from '@fullcalendar/core/locales-all'
import axios from 'axios'
import jp from 'jsonpath'
import { Modal } from 'bootstrap'
export default {
  name: 'Vehicles',
  components: {
    FullCalendar2,
    BookingForm,
  },
  data() {
    return {
      calendarOptions: {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        plugins: [resourceTimelinePlugin, iCalendarPlugin],
        initialView: 'resourceTimelineMonth',
        resourceAreaHeaderContent: 'Vehicles',
        locales: allLocales,
        locale: process.env.VUE_APP_I18N_LOCALE,
        displayEventTime: false,
        resources: [],
        eventSources: [],
        headerToolbar: {
          left: 'resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
          center: 'title',
          right: 'today prev,next',
        },
        aspectRatio: 2.1,
        datesSet: this.changedViewDates,
        eventClick: this.eventClick,
        resourceAreaWidth: "200px",
        // resourceRender: function(resourceObj, labelTds) {
        //   var resourceLabel = labelTds.find('.fc-cell-text');
        //   resourceLabel.css({'cursor': 'pointer'});
        //   resourceLabel.click(function() {
        //     this.resourceClickFunction(resourceObj);
        //   });
        // }
      },
      dateStringOptions: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' },
      resourceNames: [],
      selectedBooking: {},
      mode:'view',
      role: '',
      roles: [],
    }
  },
  methods: {
    async resourceClickFunction(resourceClickInfo) {
      console.log(resourceClickInfo)
    },
    changedViewDates(dateInfo){
      this.$emit('changed-view-dates', dateInfo.start, dateInfo.end)
    },
    async eventClick(eventClickInfo) {
      this.selectedBooking = await this.getBooking(eventClickInfo.event)
      this.mode="view"
      this.infoModal.show()
    },
    async getBooking(booking) {
      if (!booking.id) {
        return {}
      }
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/booking', {
        params: {
          uid: booking.id,
          resource: booking.extendedProps.location,
        },
        withCredentials: true,
        })
        return res.data
      } catch (error) {
        console.log(error.response.data)
      }
    },
    async editBooking(newBooking) {
      try {
        const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/booking/change',
        {
          old: {
            uid: this.selectedBooking.uid,
            resource: this.selectedBooking.resource,
          },
          new: {
            resource: newBooking.resource,
            startDate: newBooking.startDate,
            endDate: newBooking.endDate,
            summary: newBooking.summary,
            locationStart: newBooking.locationStart,
            locationEnd: newBooking.locationEnd,
            description: newBooking.acceptPassengers ? newBooking.description : '',
            acceptPassengers: newBooking.acceptPassengers,
            numberOfPassengers: newBooking.acceptPassengers ? newBooking.numberOfPassengers : 0,
            passengers: newBooking.passengers,
            service: newBooking.service,
            subresources: newBooking.subresources,
          },
        },
        { withCredentials: true },
        )
        if (res.status === 200) {
          this.infoModal.hide()
          this.refreshItems()
        }
      } catch (error) {
        console.log(error.response.data)
        //alert(this.$t('alerts.bookingconflict'))
      }
    },
    async deleteBooking(booking) {
				if (confirm('Do you realy want to delete this booking?')) {
					try {
						const res = await axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/booking', {
							params: {
								uid: booking.uid,
								resource: booking.resource,
							},
							withCredentials: true,
						})
						if (res.status === 200) {
							this.infoModal.hide()
						}
					} catch (error) {
            alert("There was an error deleting this booking. Please try again.")
						console.log(error.response.data)
					}
				}
				
			},
    async getResources() {
      try {
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/resource', {
          withCredentials: true,
        })
        if (res.status === 200) {
          this.resourceNames = jp.query(res.data.resources, '$..name')
          let roles = jp.query(res.data.resources, '$..group')
          for (let i = roles.length - 1; i >=0; i--) {
            if (!roles[i].includes(this.role)) {
              this.resourceNames.splice(i, 1)
            }
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push('login')
        } else {
          console.log(error.response.data)
        }
      }
    },
    genEventSources(resourceNames) {
      if (resourceNames.length === 0) {
        return {}
      }
      const urlParts = [process.env.VUE_APP_BACKEND_URL + '/public/ical', '?token=', process.env.VUE_APP_ACCESS_TOKEN]
      for (const name of resourceNames) {
        urlParts.push('&name=' + name)
      }
      return urlParts.join('')
    },
    refreshItems() {
      this.calendarOptions.eventSources = []
      this.calendarOptions.resources = []
      // for (let i = 0; i < this.resourceNames.length; i++) {
      for (let i = this.resourceNames.length - 1; i >= 0; i--) {
        this.calendarOptions.eventSources.push({ url: this.genEventSources([this.resourceNames[i]]), format: 'ics', eventDataTransform: function(event) {
          event.resourceId = this.resourceNames[i];
          return event;
        }.bind(this)})
        this.calendarOptions.resources.push({ id: this.resourceNames[i], title: this.resourceNames[i]})
      }
    }
  },
  beforeMount() {
    this.roles = this.$root.getRoles()
		this.role = this.roles[0]
    if (this.$root.isLoading) {
      this.$root.getUserandResources()
    }
    this.getResources()
  },
  mounted() {
		this.infoModal = new Modal(document.getElementById('bookingModal'), {})
    setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300)
	},
  watch: {
    role: function() {
      this.getResources()
    },
    resourceNames: function () {
      this.refreshItems()
    },
  }
}
</script>

<style>
td.fc-daygrid-day.fc-day-past {
  background-color: var(--bs-gray-200);
}

td.fc-daygrid-day.fc-day-other {
  background-color: var(--bs-gray-400);
}

a.fc-scrollgrid-sync-inner {
  color: var(--bs-dark);
  text-decoration: none;
}
a.fc-col-header-cell-cushion {
  color: var(--bs-dark);
  text-decoration: none;
}
div.fc-event-title {
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(var(--bs-primary-rgb), 0.25);
  font-weight: 600;
}
.fc-h-event {
  border: none;
  padding-left: 5px;
  padding-right: 5px;
}
#fullCalendar .fc-highlight {
  background: rgba(var(--bs-success-rgb), 0.15);
}
.table td {
  white-space: normal;
  word-wrap: break-word;
}
</style>