<template>
  <form class="container" @submit.prevent="filter()">
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating" style="width: 100%; max-width:300px;">
        <select class="form-select" id="floatingFuels" v-model="filterObject.fuel" style="border-radius: 15px;">
          <option v-for="fuel of this.fuels" :key="fuel">{{ fuel }}</option>
        </select>
        <label for="floatingFuels">Fuel Type</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating" style="width: 100%; max-width:300px;">
        <select class="form-select" id="floatingMakes" v-model="filterObject.make" style="border-radius: 15px;">
          <option v-for="make of this.makes" :key="make">{{ make }}</option>
        </select>
        <label for="floatingMakes">Make</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating" style="width: 100%; max-width:300px;">
        <select class="form-select" id="floatingModels" v-model="filterObject.model" style="border-radius: 15px;">
          <option v-for="model of this.models" :key="model">{{ model }}</option>
        </select>
        <label for="floatingModels">Model</label>
      </div>
    </div>
    <div class="flex-form form-signin" style="margin-top: 0.35em; margin-bottom: 0;">
      <div class="col-auto form-floating" style="max-width: 150px">
        <input 
          class="form-control" 
          id="floatingInputLicPlate" 
          placeholder="License Plate" 
          type="text"
          @blur="blurInput" 
          style="border-radius: 15px;" 
          v-model="filterObject.licPlate"
        />
        <label for="floatingInputLicPlate">License Plate</label>
      </div>
      <div class="col-auto form-floating" style="max-width: 150px">
        <input 
          class="form-control" 
          id="floatingInputLicPlate" 
          placeholder="Passenger Seats" 
          type="text"
          @blur="numberInputController"
          @focus="numberInputController"
          style="border-radius: 15px;" 
          v-model="filterObject.passengerSeats"
        />
        <label for="floatingInputLicPlate">Passenger Seats</label>
      </div>
    </div>
    <div class="mb-3 flex-form" style="margin-top:0.35em;">
      <button type="submit" class="btn-powertrip" style="width:auto; height:auto; padding: .375rem .75rem; font-size: 1rem;">
        Filter
      </button>
      <button type="button" class="btn-cancel" v-on:click="this.$emit('cancel')">
        {{ $t('labels.cancel') }}
      </button>
      <button type="button" class="btn-delete" v-on:click="clear()">
        Clear
      </button>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  name: 'BookingForm',
  props: ['resources'],
  data() {
    return {
      makes: [''],
      models: [''],
      fuels: [''],
      filterObject: {
        make: '',
        model: '',
        licPlate: '',
        passengerSeats: '',
        fuel: '',
        numberOfFilters: 0,
      }
    }
  },
  methods: {
    filter() {
      this.filterObject.numberOfFilters = 0;
      for (const [key, value] of Object.entries(this.filterObject)) {
        if (key !== 'numberOfFilters' && value) this.filterObject.numberOfFilters++
      }
      this.$emit('filter', this.filterObject)
    },
    clear() {
      this.makes = [''];
      this.models = [''];
      this.fuels = [''];
      this.filterObject = {
        make: '',
        model: '',
        licPlate: '',
        passengerSeats: '',
        fuel: '',
        numberOfFilters: 0,
      };
      this.getDropdownData()
      this.$emit('clear')
    },
    blurInput(obj) {
      //change the type back to text if nothing has been inputted.
      if(!obj.target.value) obj.target.type = 'text'
    },
    numberInputController(obj) {
      console.log(obj)
      if (obj.type === "focus") {
        //obj going into focus
        obj.target.type = "number";
        obj.target.min = 0;
        obj.target.max = 10;
      }
      if (obj.type === "blur") {
        if (!obj.target.value) {
          obj.target.type = 'text';
          return;
        }
        if (obj.target.value > 10) obj.target.value = 10;
        if (obj.target.value < 0) obj.target.value = 0;
      }
    },
    getDropdownData() {
      //get the makes models and fuel required for the appropriate filtering
      this.makes = ['']
      this.models = ['']
      this.fuels = ['']
      for (let i = 0; i < this.resources.length; i++) {
        if (!this.makes.includes(this.resources[i].make)) this.makes.push(this.resources[i].make);
        if (!this.models.includes(this.resources[i].model)) this.models.push(this.resources[i].model)
        if (!this.fuels.includes(this.resources[i].fuel)) this.fuels.push(this.resources[i].fuel);
      }
    }
  },
  mounted() {},
  watch: {
    resources: function() {
      this.getDropdownData()
    }
  },
}
</script>

<style>
  .btn-cancel {
    margin-left: 5px;
		background-color: #e4e4e4;
		color: #333333;
		width: auto;
		border: 1px solid transparent;
		border-radius: 15px;
		height: auto;
		font-size: 1rem;
    padding: .375rem .75rem;
	}
  .btn-delete {
    margin-left: 5px;
		background-color: #bb2d3b;
		color: white;
		width: auto;
		border: 1px solid transparent;
		border-radius: 15px;
		height: auto;
		font-size: 1rem;
    padding: .375rem .75rem;
  }
  .btn-delete:hover {
		background-color: #b43d49;
	}
  .btn-delete:active {
		background-color: #b8202f;
	}
  .btn-delete:focus {
		box-shadow: 0 0 0 0.2rem rgba(255, 136, 136, 0.25)
	}

	.btn-cancel:hover {
		background-color: #d4d4d4;
	}
	.btn-cancel:active {
		background-color: #cccccc;
	}
	.btn-cancel:focus {
		box-shadow: 0 0 0 0.2rem rgba(160, 160, 160, 0.25)
	}
</style>