import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Keycloak from 'keycloak-js'
import axios from 'axios'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import "@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "@fortawesome/fontawesome-free/css/brands.min.css"

import i18n from './i18n'


let initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL, 
    realm: process.env.VUE_APP_KEYCLOAK_REALM, 
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT, 
    onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);

keycloak.init({ 
    onLoad: 'login-required',
    checkLoginIframe: false
}).then((auth) => {
    if (!auth) {
        window.location.reload(); //reload login page
    } else {
        console.log("Authenticated");
        createApp(App, {keycloak: keycloak}).use(i18n).use(router).mount('#app'); //create the vue app if authenticated
        axios.defaults.headers.common['Authorization'] = 'Bearer' + keycloak.token
    }
    
    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
            console.log('Token refreshed' + refreshed);
        } else {
            console.log('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
        }).catch(() => {
            console.log('Failed to refresh token');
        });
    }, 60000)
}).catch(() => {
    console.log("Authenticated Failed");
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        // Get the actual url of the app, it's needed for Keycloak
        const basePath = window.location.toString()
        if (!keycloak.authenticated) {
            // The page is protected and the user is not authenticated. Force a login.
            keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
            console.log("Not Authenticated")
        } else if (keycloak.hasResourceRole('general_user')) {
            // The user was authenticated, and has the app role
            let allowed = true
            if (to.meta.admin) {
                allowed = keycloak.hasResourceRole('admin') ? true : false
            }
            if (allowed) {
                keycloak.updateToken(70)
                .then(() => {
                    next()
                    console.log("Authenticated and authorised to view page")
                })
                .catch(err => {
                    console.error(err)
                })
            } else {
                next({ name: 'Unauthorised' })
                console.log("Unauthorised to view this page")
            }
        } else {
            // The user was authenticated, but did not have the correct role
            // Redirect to an error page
            next({ name: 'Unauthorised' })
            console.log("Unauthorised to view this page")
        }
    } else {
        // This page did not require authentication
        next()
        console.log("No Authentication Required")
    }
  })